import React from "react";
import PropTypes from "prop-types";
import TableCommon from "../../common/TableCommon";
import DialogComponent from "../../common/DialogComponent";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as RouteActions from "../../../actions/RouteActions";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LinearProgress from '@material-ui/core/LinearProgress';

toast.configure();
class BusinessRouteAllClientComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      clientes: [],
      businessRoute: [],
      businessRouteSelect: {},
      open: this.props.open,
      hidden: this.props.hidden,
      newBusinessRoute: this.props.newBusinessRoute,
      onChange: false,
    };
  }

  componentDidUpdate(prevState) {
    // Uso tipico (no olvides de comparar las props):
    const { isLoading, message, messageCode } = this.props;
    console.log("isLoading=>", isLoading)
    console.log("prevProps=>", prevState.isLoading)
    if(prevState.isLoading && !isLoading && message && messageCode){
      this.handleResult(message, messageCode);
    }
    
  }
  handleResult = (message, code) => {
    if (code === 200) {
      toast.info(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "fw-bold",
      });
    } else {
      toast.error(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "fw-bold",
      });
    }
  };

  asignarCloseModal = (modalParameter) => {
    this.setState({
      closeModal: modalParameter,
    });
  };

  handleClose = () => {
    this.setState({
      openDialog: false,
    });
  };

  handleAccept = async (businessRouteSelect) => {
    const { addBusinessRouteToAClient, history } = this.props;
    await addBusinessRouteToAClient(
      businessRouteSelect.businessRouteId,
      history
    );
    this.handleClose();
    this.setState({
      onChange: true,
    });
  };

  renderDialog() {
    const { openDialog, businessRouteSelect } = this.state;
    return (
      <DialogComponent
        open={openDialog}
        handleClose={() => this.handleClose()}
        title="Asignar ruta"
        handleAccept={() => this.handleAccept(businessRouteSelect)}
      >
        <div>
          Esta acción asignará la ruta seleccionada a su cliente actual ¿está
          seguro qué desea continuar con la operación?
        </div>
      </DialogComponent>
    );
  }

  handleSaveSelect = (_, rowData) => {
    this.setState({
      openDialog: true,
      businessRouteSelect: rowData,
    });
  };

  handleChange = (event) => {
    let clientId = event.target.value;
    if (clientId !== "") {
      this.setState({
        businessRoute: this.props.clientes.find((o) => o.clientId === clientId)
          .businessRoute,
      });
    } else {
      this.setState({
        businessRoute: [],
      });
    }
  };

  handleSetState = (clientesParametros) => {
    this.setState({
      clientes: clientesParametros,
    });
  };

  render() {
    const { clientes, open, hidden, isLoading } =
      this.props;

    return (
      <div>
        {}
        {open && !hidden && (
          <p id="textoModal">Seleccióne nuevo o existente.</p>
        )}

        {!isLoading && open && hidden && clientes && (
          <select className="form-select mb-4" onChange={this.handleChange}>
            <option key="1" value="">
              Seleccione un cliente
            </option>

            {clientes &&
              clientes.map((cliente) => (
                <option key={cliente.clientId} value={cliente.clientId}>
                  {cliente.name}
                </option>
              ))}
          </select>
        )}
        {isLoading && <LinearProgress />}
        {open && !isLoading && this.state.businessRoute.length > 0 && (
          <TableCommon
            title="Rutas empresariales del cliente"
            columns={[
              { title: "Nombre", field: "name" },
              { title: "Cantidad de cupos", field: "capacity" },
              { title: "Hora de inicio", field: "startHour" },
              { title: "Hora de fin", field: "endHour" },
            ]}
            items={this.state.businessRoute}
            actions={[
              {
                icon: "save",
                tooltip: "Save User",
                onClick: (event, rowData) => {
                  this.handleSaveSelect(event, rowData);
                },
              },
            ]}
            components={{
              Action: (props) => (
                <Button
                  onClick={(event) => props.action.onClick(event, props.data)}
                  color="primary"
                  variant="contained"
                  style={{ textTransform: "none" }}
                  size="small"
                >
                  Select
                </Button>
              ),
            }}
          />
        )}
        {this.renderDialog()}
      </div>
    );
  }
}

BusinessRouteAllClientComponent.propTypes = {
  message: PropTypes.string,
  messageCode: PropTypes.string,
  addBusinessRouteToAClient: PropTypes.func,
  isLoading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  message: state.route.message,
  messageCode: state.route.messageCode,
  addBusinessRouteToAClient: state.route.addbusinessRouteToAClient,
});

const mapDispatchToProps = (dispatch) => ({
  addBusinessRouteToAClient: bindActionCreators(
    RouteActions.addBusinessRouteToAClient,
    dispatch
  ),
});

//export default BusinessRouteAllClientComponent;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BusinessRouteAllClientComponent));

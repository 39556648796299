const accountPageStyles = () => ({
  root: {
    margin: "0 auto",
    "padding-top": "5%",
    width: "50%",
  },
});

export {
  // eslint-disable-next-line import/prefer-default-export
  accountPageStyles as AccountPageStyles,
};

import React, { Component } from "react";
import RootPage from "../RootPage";

class IndicatorPage extends Component {
	render() {
		return (
			<RootPage>
				<h1>Indicator page</h1>
			</RootPage>
		);
	}
}
export default IndicatorPage;

/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, withStyles } from "@material-ui/core";
import { withRouter } from "react-router";
import { getTokenPayload } from "../../../helpers/AppHelper";
import * as RouteActions from "../../../actions/RouteActions";
import { BusinessListStyles } from "./BusinessStyles";
import * as routes from "../../../constants/routes";
import TableCommon from "../../common/TableCommon";
import DialogComponent from "../../common/DialogComponent";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BusinessRouteAllClientComponent from "./BusinessRouteAllClientComponent";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import LinearProgress from "@material-ui/core/LinearProgress";

toast.configure();
class BusinessListComponent extends Component {
  constructor() {
    super();
    this.state = {
      openDialog: false,
      businessId: null,
      businessRouteClient: [],
      open: false,
      hidden: false,
      scroll: "paper",
      handleShow: this.HandleShow,
      newBusinessRoute: this.newBusinessRoute,
    };
  }

  async componentDidMount() {
    const { getBusinessRoutes } = this.props;
    const { clientId } = getTokenPayload();
    await getBusinessRoutes(clientId);
  }

  newBusinessRoute = () => {
    const { history } = this.props;
    history.push(routes.PATH_CLIENT_BUSINESS_NEW);
  };

  deleteBusinessRoute = async (rowData) => {
    const { deleteBusinessRoute } = this.props;
    await deleteBusinessRoute(rowData.businessRouteId);
  };

  editBusinessRoute = (_, rowData) => {
    const { history } = this.props;
    history.push(
      `${routes.PATH_CLIENT_BUSINESS_EDIT}/${rowData.businessRouteId}`
    );
  };

  removeReservesBusinessRoute = (_, rowData) => {
    const { history } = this.props;
    history.push(
      `${routes.PATH_CLIENT_BUSINESS_EDIT}/${rowData.businessRouteId}`
    );
  };

  handleAccept = async (businessId) => {
    const { removeBusinessReserves } = this.props;
    await removeBusinessReserves(businessId);
    this.handleCloseTwo();
  };

  handleCloseTwo = () => {
    this.setState({
      openDialog: false,
      businessId: null,
    });
  };

  confirmRemoveReserves = (_, rowData) => {
    this.setState({
      openDialog: true,
      businessId: rowData.businessRouteId,
    });
  };

  renderDialog() {
    const { openDialog, businessId } = this.state;
    return (
      <DialogComponent
        open={openDialog}
        handleClose={() => this.handleCloseTwo()}
        title="Liberar los cupos de la ruta empresarial"
        handleAccept={() => this.handleAccept(businessId)}
      >
        <div>
          Esta acción permite liberar todos los cupos de la ruta empresarial
          ¿está seguro qué desea continuar con la operación?
        </div>
      </DialogComponent>
    );
  }

  handleCloseModal = () => {
    console.log("closeModal btn cerrar antes", this.state.closeModal);
    this.setState({
      closeModal: true,
    });
    console.log("closeModal btn cerrar", this.state.closeModal);
  };

  HandleShow = () => {
    const { getBusinessRouteAllClient } = this.props;
    this.setState({
      open: true,
      hidden: true,
    });
    getBusinessRouteAllClient();
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
      hidden: false,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
      hidden: false,
    });
  };

  Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

  render() {
    const { businessRoutes, classes, businessRouteAllClient, isLoading } =
      this.props;
    return (
      <div className={classes.root}>
        {isLoading && <LinearProgress />}
        <TableCommon
          title="Rutas empresariales"
          columns={[
            { title: "Nombre", field: "name" },
            { title: "Cantidad de cupos", field: "capacity" },
            //  { title: "Personas con reserva", field: "passangerQuantity" },
            { title: "Hora de inicio", field: "startHour" },
            { title: "Hora de fin", field: "endHour" },
            { title: "Origen latitud", field: "origin.latitude" },
            { title: "Origen longitud", field: "origin.longitude" },
            { title: "Destion latitud", field: "destination.latitude" },
            { title: "Destion longitud", field: "destination.longitude" },
          ]}
          items={businessRoutes}
          editable={{
            onRowDelete: this.deleteBusinessRoute,
          }}
          actions={[
            {
              icon: "edit",
              tooltip: "Editar",
              onClick: (event, rowData) =>
                this.editBusinessRoute(event, rowData),
            },
            {
              icon: "cleaning_services",
              tooltip: "Liberar reservas",
              onClick: (event, rowData) =>
                this.confirmRemoveReserves(event, rowData),
            },
          ]}
        />
        <div className={classes.addNew}>
          <Button
            variant="contained"
            color="primary"
            className="mb-4"
            onClick={this.handleClickOpen}
          >
            Agregar
          </Button>
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            TransitionComponent={this.Transition}
            scroll={this.state.scroll}
            maxWidth={"lg"}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" onClose={this.handleClose}>
              <div className="row">
                <div className="col-6">Rutas Empresariales</div>
                <div className="col-6 text-end">
                  <IconButton
                    edge="end"
                    color="primary"
                    onClick={this.handleClose}
                    aria-label="close"
                    className="text-end"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
            </DialogTitle>

            <DialogContent dividers={this.state.scroll === "paper"}>
              <DialogContentText id="alert-dialog-description">
                <BusinessRouteAllClientComponent
                  clientes={businessRouteAllClient}
                  open={this.state.open}
                  hidden={this.state.hidden}
                  handleShow={this.state.handleShow}
                  newBusinessRoute={this.state.newBusinessRoute}
                  isLoading={isLoading}
                ></BusinessRouteAllClientComponent>
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <div className="col text-center" hidden={this.state.hidden}>
                <Button
                  autoFocus
                  onClick={this.newBusinessRoute}
                  color="primary"
                  variant="contained"
                  className="m-1"
                >
                  Nuevo
                </Button>
                <Button
                  id="btnExistente"
                  color="primary"
                  variant="contained"
                  className="m-1"
                  onClick={this.HandleShow}
                >
                  Existente
                </Button>
              </div>
            </DialogActions>
          </Dialog>
        </div>
        {this.renderDialog()}
        
      </div>
    );
  }
}

BusinessListComponent.propTypes = {
  businessRoutes: PropTypes.array,
  businessRouteAllClient: PropTypes.array,
  getBusinessRoutes: PropTypes.func,
  deleteBusinessRoute: PropTypes.func,
  getBusinessRouteAllClient: PropTypes.func,
  classes: PropTypes.object,
  isLoading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  businessRoutes: state.route.businessRoutes,
  businessRouteAllClient: state.route.businessRouteAllClient,
  isLoading: state.route.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getBusinessRoutes: bindActionCreators(
    RouteActions.getBusinessRoutesClient,
    dispatch
  ),
  deleteBusinessRoute: bindActionCreators(
    RouteActions.deleteBusinessRouteById,
    dispatch
  ),
  removeBusinessReserves: bindActionCreators(
    RouteActions.removeBusinessReserves,
    dispatch
  ),
  getBusinessRouteAllClient: bindActionCreators(
    RouteActions.getBusinessRouteAllClient,
    dispatch
  ),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(BusinessListStyles)(BusinessListComponent))
);

export async function addNotifications(httpClient, newNotifications) {
	return httpClient.post(`/v1/Notifications`, newNotifications);
}

export async function getNotifications(httpClient) {
	return httpClient.get(`/v1/Notifications`);
}

export async function getAllDomains(httpClient) {
	return httpClient.get(`/v1/Notifications/domains`);
}

export async function deleteNotifications(httpClient, notificationsId) {
	console.log('notificationsId api', notificationsId);
	return httpClient.put(`/v1/Notifications/${notificationsId}`);
}
const verificationStyles = () => ({
	alert: {
		margin: "0 auto",
		width: "100%",
		"justify-content": "center"
	}
});

export {
	verificationStyles as VerificationStyles
};

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import LoginComponent from "../../components/account/login/LoginComponent";
import AccountPage from "./AccountPage";
class LoginPage extends Component {
  render() {
    const { message, messageCode, isLoading } = this.props;
    return (
      <AccountPage
        message={message}
        messageCode={messageCode}
        isLoading={isLoading}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src="/images/LogoAppimotion_Original ver@2x.png"
            alt="logo"
            style={{
              margin: "auto",
              width: "300px",
              maxWidth: "100%",
            }}
          />
        </div>
        <LoginComponent />
      </AccountPage>
    );
  }
}

LoginPage.propTypes = {
  messageCode: PropTypes.number,
  message: PropTypes.string,
  isLoading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  message: state.login.message,
  messageCode: state.login.messageCode,
  isLoading: state.login.isLoading,
});

export default connect(mapStateToProps)(LoginPage);

const officesListStyles = () => ({
	root: {

	},
	addNew: {
		paddingTop: 10,
		display: "flex",
		justifyContent: "flex-end"
	}
});

export {
	// eslint-disable-next-line import/prefer-default-export
	officesListStyles as OfficesListStyles
};

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as AwardActions from "../../../../actions/AwardActions";
import { getTokenPayload } from "../../../../helpers/AppHelper";
import TableCommon from "../../../common/TableCommon";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@material-ui/core";

toast.configure();

class AwardComponent extends Component {
  async componentDidMount() {
    const { getAllAwards } = this.props;
    const { clientId } = getTokenPayload();
    await getAllAwards(clientId);
  }

  constructor() {
    super();
    this.state = {
      contNum: 1,
      open: false, // Agregamos el estado "open" para controlar el diálogo
      description: "", // Estado para el campo "Descripción"
      score: "", // Estado para el campo "Puntos"
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // Verificar si hubo un cambio en messageCode y contNum es igual a 1
    if (this.props.messageCode === 3303 && this.state.contNum === 1) {
      this.repeatedAward();

      // Actualizar el estado aquí, después de que las acciones adicionales se hayan realizado.
      this.setState({
        contNum: 0,
      });
    }
  }

  addNewAward = async () => {
    const { clientId } = getTokenPayload();
    const { addAward } = this.props;
    const { description, score } = this.state;

    if (!description || !score) {
      toast.error("¡Debe llenar todos los campos para agregar!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "fw-bold",
      });
    } else if (Number.isNaN(score)) {
      toast.error(
        `¡"${score}" no es un número válido, no se pudo agregar el premio!`,
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } else {
      const newAward = {
        description,
        score: parseInt(score),
        clientId,
      };
      await addAward(newAward);
      this.handleClose();
    }
  };

  updateAward = async (newData, oldData) => {
    const { updateAward } = this.props;
    if (newData.description.length === 0 || newData.score.length === 0) {
      toast.error("¡Debe llenar todos los campos para actualizar el premio!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (Number.isNaN(newData.score)) {
      toast.error(
        `¡"${newData.score}" no es un número válido, no se pudo actualizar el premio!`,
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } else {
      await updateAward(oldData.awardId, newData);
    }
  };

  deleteAward = async (oldData) => {
    const { deleteAward } = this.props;
    await deleteAward(oldData.awardId);
  };

  repeatedAward = async () => {
    toast.error(
      "El premio que intenta registrar, ya se encuentra en el sistema",
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "fw-bold",
      }
    );
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false, description: "", score: "" });
  };

  render() {
    const { awards } = this.props;

    return (
      <div>
        <TableCommon
          title="Premios"
          columns={[
            { title: "Descripción", field: "description" },
            { title: "Puntos", field: "score" },
          ]}
          items={awards}
          editable={{
            onRowUpdate: this.updateAward,
            onRowDelete: this.deleteAward,
          }}
        />

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Agregar Premio</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Descripción"
              type="text"
              fullWidth
              value={this.state.description}
              onChange={(e) => this.setState({ description: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Puntos"
              type="number"
              fullWidth
              value={this.state.score}
              onChange={(e) => this.setState({ score: e.target.value })}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={this.addNewAward} color="primary">
              Agregar
            </Button>
          </DialogActions>
        </Dialog>
        <div
          className="m-3"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {" "}
          <Button
            variant="contained"
            color="primary"
            className="mb-4"
            onClick={this.handleOpen} // Llamamos al método handleOpen al hacer clic en el botón
          >
            Agregar
          </Button>
        </div>
      </div>
    );
  }
}

AwardComponent.propTypes = {
  getAllAwards: PropTypes.func,
  addAward: PropTypes.func,
  deleteAward: PropTypes.func,
  updateAward: PropTypes.func,
  awards: PropTypes.array,
};

const mapStateToProps = (state) => ({
  awards: [...state.award.awards],
  messageCode: state.award.messageCode,
});

const mapDispatchToProps = (dispatch) => ({
  getAllAwards: bindActionCreators(AwardActions.getClientAwards, dispatch),
  addAward: bindActionCreators(AwardActions.addClientAward, dispatch),
  deleteAward: bindActionCreators(AwardActions.deleteClientAward, dispatch),
  updateAward: bindActionCreators(AwardActions.updateClientAward, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AwardComponent);

import initialState from "../../store/initialState";

const paginationData = {
	totalItems: 0,
	currentPage: 0,
	items: []
};
const usersZone = [];

const userInitialState = {
	...initialState,
	paginationData,
	usersZone
};

export default userInitialState;

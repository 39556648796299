/* eslint-disable */
import accountLogic from "./AccountLogic";
import awardLogic from "./AwardLogic";
import userLogic from "./UserLogic";
import routeLogic from "./RouteLogic";
import userRouteLogic from "./UserRouteLogic";
import transportTypeLogic from "./TransportTypeLogic";
import scoreLogic from "./ScoreLogic";
import areaLogic from "./AreaLogic";
import campaingLogic from "./CampaignLogic";
import scheduleLogic from "./ScheduleLogic";
import officeLogic from "./OfficeLogic";
import clientConfigLogic from "./ClientConfigLogic";
import notificationLogic from "./NotificationsLogic";

export default [
  ...accountLogic,
  ...awardLogic,
  ...userLogic,
  ...routeLogic,
  ...userRouteLogic,
  ...transportTypeLogic,
  ...scoreLogic,
  ...areaLogic,
  ...campaingLogic,
  ...scheduleLogic,
  ...officeLogic,
  ...clientConfigLogic,
  ...notificationLogic,
];

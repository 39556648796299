import React, { Component } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Formik } from "formik";
import * as Yup from "yup";
import * as AccountActions from "../../../actions/AccountActions";
import ResetPasswordFormComponent from "./ResetPasswordFormComponent";

import ErrorMessages from "../../../constants/messages";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Redirect } from "react-router-dom";
import { PATH_RESET_PASSWORD_COMPLETED } from "../../../constants/routes";

class ResetPasswordComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToSuccessPage: false,
    };
  }

  resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(12, (value) => ErrorMessages.general.minRequired(value.min))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-!#$%&'()*+,./:;<=>?@^_{|}`~])(?!\s)[a-zA-Z\d\-!#$%&'()*+,./:;<=>?@^_{|}`~]{12,}$/,
        "La contraseña debe cumplir la política de contraseñas."
      )
      .required(ErrorMessages.general.fieldRequired),
    confirmPassword: Yup.string()
      .required(ErrorMessages.general.fieldRequired)
      .oneOf(
        [Yup.ref("password"), null],
        ErrorMessages.account.resetPassword.confirmPassword
      ),
  });

  componentDidUpdate(prevProps) {
    const { resetPasswordStatus } = this.props;

    if (resetPasswordStatus !== prevProps.resetPasswordStatus) {
      if (resetPasswordStatus === "success") {
        toast.success("¡Contraseña restablecida con éxito!");
        this.setState({ redirectToSuccessPage: true });
      } else if (resetPasswordStatus === "failure") {
        toast.error("¡Algo ha salido mal!");
      }
    }
  }

  handleSubmit = async (values) => {
    const { resetPasswordPost, token } = this.props;

    try {
      await resetPasswordPost(values.password, token);
      toast.success("¡Contraseña restablecida con éxito!");
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message ||
        "¡Error al restablecer la contraseña!";
      toast.error(errorMessage);
    }
  };

  render() {
    const { resetPasswordData, resetPasswordStatus } = this.props;
    const { redirectToSuccessPage } = this.state;

    if (redirectToSuccessPage && resetPasswordStatus === "success") {
      return <Redirect to={PATH_RESET_PASSWORD_COMPLETED} />;
    }

    return (
      <Formik
        initialValues={resetPasswordData}
        validationSchema={this.resetPasswordSchema}
        onSubmit={this.handleSubmit}
        component={ResetPasswordFormComponent}
        enableReinitialize
      />
    );
  }
}

ResetPasswordComponent.propTypes = {
  resetPasswordData: PropTypes.object,
  resetPasswordPost: PropTypes.func,
  resetPasswordStatus: PropTypes.string, // Prop para el estado del restablecimiento de contraseña
};

const mapStateToProps = (state) => ({
  isLoading: state.resetPass.isLoading,
  resetPasswordData: state.resetPass.resetPasswordData,
  resetPasswordStatus: state.resetPass.resetPasswordStatus, // Obtén el estado del restablecimiento de contraseña
});

const mapDispatchToProps = (dispatch) => ({
  resetPasswordPost: bindActionCreators(
    AccountActions.resetPasswordAction,
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordComponent);

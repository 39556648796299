import React from "react";
import RootPage from "../RootPage";

function DashboardPage() {
  return (
    <RootPage>
      <div style={{ textAlign: "center", marginTop: "20%" }}>
        <h1>Bienvenido a Appimotion</h1>
        <p>
          Aquí puedes administrar todas las funciones proporcionadas por la
          solución.
        </p>
      </div>
    </RootPage>
  );
}

export default DashboardPage;

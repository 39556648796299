import notificationsInitialState from "./NotificationsInitialState";
import * as actionTypes from "../../constants/actionTypes";

const notificationsReducer = (state = notificationsInitialState, action) => {
	switch (action.type) {
		case actionTypes.ACTION_ADD_NOTIFICATIONS: {
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_ADD_NOTIFICATIONS_SUCCESS: {
			return {
				...state,
				isLoading: false,
				messageCode: action.payload.messageCode,
			};
		}
		case actionTypes.ACTION_ADD_NOTIFICATIONS_FAILURE: {
			return {
				...state,
				isLoading: false,
				messageCode: action.payload.messageCode,
			};
		}
		case actionTypes.ACTION_GET_NOTIFICATIONS: {
			return {
				...state,
				isLoading: true,
			};
		} case actionTypes.ACTION_GET_NOTIFICATIONS_SUCCESS: {
			return {
				...state,
				isLoading: false,
				notifications: action.payload.notifications
			};
		}
		case actionTypes.ACTION_GET_NOTIFICATIONS_FAILURE: {
			return {
				...state,
				isLoading: false,
				error: action.error,
				message: action.payload.errorMessage,
			};
		}
		case actionTypes.ACTION_GET_DOMAINS: {
			return {
				...state,
				isLoading: true,
			};
		} case actionTypes.ACTION_GET_DOMAINS_SUCCESS: {
			return {
				...state,
				isLoading: false,
				domains: action.payload.domains
			};
		}
		case actionTypes.ACTION_GET_DOMAINS_FAILURE: {
			return {
				...state,
				isLoading: false,
				error: action.error,
				message: action.payload.errorMessage,
			};
		}
		case actionTypes.ACTION_DELETE_NOTIFICATIONS: {
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_DELETE_NOTIFICATIONS_SUCCESS: {
			return {
				...state,
				isLoading: false,
			};
		}
		case actionTypes.ACTION_DELETE_NOTIFICATIONS_FAILURE: {
			return {
				...state,
				isLoading: false,
				error: action.error,
				message: action.payload.errorMessage
			};
		}
		default: {
			return {
				...state
			};
		}
	}
};

export default notificationsReducer;
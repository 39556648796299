import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
  },
}));

function ResetPasswordCompleted() {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.container}
      alignItems="center"
      justify="center"
    >
      <Grid item xs={12}>
        <div className={classes.contentContainer}>
          <Typography variant="h4" className={classes.title}>
            Cambio de Contraseña Exitoso
          </Typography>
          <Typography variant="body1">
            Tu contraseña ha sido restablecida con éxito.
          </Typography>
          <Typography variant="body1">
            Puedes iniciar sesión con tu nueva contraseña.
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="login-info">
          <div className="full-item text-center">
            <span>{`Gracias por usar Appimotion © ${new Date().getFullYear()}`}</span>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default ResetPasswordCompleted;

import * as actionTypes from "../constants/actionTypes";

export function addNotifications(newNotifications) {
	return {
		type: actionTypes.ACTION_ADD_NOTIFICATIONS,
		payload: {
			newNotifications
		}
	};
}

export function addNotificationsSuccess(message, messageCode) {
	return {
		type: actionTypes.ACTION_ADD_NOTIFICATIONS_SUCCESS,
		payload: {
			message,
			messageCode
		}
	};
}

export function addNotificationsFailure(message, messageCode) {
	return {
		type: actionTypes.ACTION_ADD_NOTIFICATIONS_FAILURE,
		payload: {
			message,
			messageCode
		}
	};
}

// get notifications
export function getNotifications() {
	return {
		type: actionTypes.ACTION_GET_NOTIFICATIONS,
		payload: {
		}
	};
}

export function getNotificationsSuccess(notifications) {
	return {
		type: actionTypes.ACTION_GET_NOTIFICATIONS_SUCCESS,
		payload: {
			notifications
		}
	};
}

export function getNotificationsFailure(errorMessage) {
	return {
		type: actionTypes.ACTION_GET_NOTIFICATIONS_FAILURE,
		payload: {
			errorMessage
		}
	};
}


// get domains
export function getAllDomains() {
	return {
		type: actionTypes.ACTION_GET_DOMAINS,
		payload: {
		}
	};
}

export function getAllDomainsSuccess(domains) {
	return {
		type: actionTypes.ACTION_GET_DOMAINS_SUCCESS,
		payload: {
			domains
		}
	};
}

export function getAllDomainsFailure(errorMessage) {
	return {
		type: actionTypes.ACTION_GET_DOMAINS_FAILURE,
		payload: {
			errorMessage
		}
	};
}

// Delete noptification
export function deleteNotification(notificationsId) {
	return {
		type: actionTypes.ACTION_DELETE_NOTIFICATIONS,
		payload: {
			notificationsId
		}
	};
}

export function deleteNotificationSuccess(messageCode) {
	return {
		type: actionTypes.ACTION_DELETE_NOTIFICATIONS_SUCCESS,
		payload: {
			messageCode
		}
	};
}

export function deleteNotificationFailure(errorMessage) {
	return {
		type: actionTypes.ACTION_DELETE_NOTIFICATIONS_FAILURE,
		payload: {
			errorMessage
		}
	};
}
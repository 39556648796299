import React, { Component } from "react";
import RootPage from "../RootPage";

class TravelPage extends Component {
	render() {
		return (
			<RootPage>
				<h1>Travel page</h1>
			</RootPage>
		);
	}
}
export default TravelPage;

import React, { Component } from "react";
import TableCommon from "../../../common/TableCommon";
import { toast } from 'react-toastify';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as NotificationsActions from "../../../../actions/notificationsActions"
import 'react-toastify/dist/ReactToastify.css';


toast.configure();
class NotificationsListComponent extends Component {

    async componentDidMount() {
        const { getAllNotifications } = this.props;
        getAllNotifications();
      }


    constructor() {
        super();

        this.state = {
            title: "",
            content: ""
        }
    }

    deleteNotification = async (oldData) => {
		const { deleteNotification } = this.props;
		await deleteNotification(oldData.notificationsId);
	}

    render() {

        const { notifications } = this.props;

        return (

            <div>
                <TableCommon
                    title="Notificaciones Activas"
                    columns={[
                        { title: "id", field: "notificationsId" },
                        { title: "Titulo", field: "title" },
                        { title: "Mensaje", field: "content" },
                        { title: "Estado de Notificación", field: "status" },
                        { title: "Fecha de Creación", field: "createdDate" },
                        { title: "Fecha de envio", field: "shippingDate" }           
                    ]}
                    items={notifications}
                    editable={{
                        onRowDelete: this.deleteNotification
                    }}
                />

            </div>


        );
    };
}

NotificationsListComponent.propTypes = {
    getAllNotifications: PropTypes.func,
    notifications: PropTypes.array,
    deleteNotification: PropTypes.func
        /*updateAward: PropTypes.func */
};


const mapStateToProps = state => ({
    notifications: [...state.notification.notifications]
});

const mapDispatchToProps = dispatch => ({
    getAllNotifications: bindActionCreators(NotificationsActions.getNotifications, dispatch),
    deleteNotification: bindActionCreators(NotificationsActions.deleteNotification, dispatch),
       /* updateAward: bindActionCreators(AwardActions.updateClientAward, dispatch), */

});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsListComponent);




/* eslint-disable */
import { createLogic } from "redux-logic";

import * as notificationsActions from "../actions/notificationsActions";
import * as notificationsApi from "../api/v1/notificationsApi";
import * as actionTypes from "../constants/actionTypes";

const getNotificationsLogic = createLogic({
  type: actionTypes.ACTION_GET_NOTIFICATIONS,
  latest: true,
  async process({ httpClient }, dispatch, done) {
    try {
      const response = await notificationsApi.getNotifications(httpClient);
      await dispatch(
        notificationsActions.getNotificationsSuccess(response.data.list)
      );
    } catch (err) {
      await dispatch(
        notificationsActions.getNotificationsFailure(err.messageText)
      );
    }
    done();
  },
});

const getAllDomainsLogic = createLogic({
  type: actionTypes.ACTION_GET_DOMAINS,
  latest: true,
  async process({ httpClient }, dispatch, done) {
    try {
      const response = await notificationsApi.getAllDomains(httpClient);
      await dispatch(
        notificationsActions.getAllDomainsSuccess(response.data.list)
      );
    } catch (err) {
      await dispatch(
        notificationsActions.getAllDomainsFailure(err.messageText)
      );
    }
    done();
  },
});

const addNotificationsLogic = createLogic({
  type: actionTypes.ACTION_ADD_NOTIFICATIONS,
  latest: true,
  async process({ httpClient, action }, dispatch, done) {
    try {
      const { newNotifications } = action.payload;
      const response = await notificationsApi.addNotifications(
        httpClient,
        newNotifications
      );

      if (response.data.valid) {
        await dispatch(
          notificationsActions.addNotificationsSuccess(
            response.data.messageText,
            response.data.messageCode
          )
        );
      } else {
        await dispatch(
          notificationsActions.addNotificationsFailure(
            response.data.messageText,
            response.data.messageCode
          )
        );
      }
    } catch (err) {
      await dispatch(notificationsActions.addNotificationsFailure(err.message));
    }
    done();
  },
});

const deleteNotificationLogic = createLogic({
  type: actionTypes.ACTION_DELETE_NOTIFICATIONS,
  latest: true,
  async process({ httpClient, action }, dispatch, done) {
    try {
      const { notificationsId } = action.payload;
      const response = await notificationsApi.deleteNotifications(
        httpClient,
        notificationsId
      );

      if (response.data.valid) {
        await dispatch(
          notificationsActions.deleteNotificationSuccess(
            response.data.messageCode
          )
        );

        // Load awards
        try {
          const response = await notificationsApi.getAllDomains(httpClient);
          await dispatch(
            notificationsActions.getAllDomainsSuccess(response.data.list)
          );
        } catch (err) {
          await dispatch(
            notificationsActions.getAllDomainsFailure(err.messageText)
          );
        }
      }
    } catch (err) {
      await dispatch(
        notificationsActions.deleteNotificationFailure(err.messageText)
      );
    }
    done();
  },
});

export default [
  addNotificationsLogic,
  getNotificationsLogic,
  getAllDomainsLogic,
  deleteNotificationLogic,
];

import React from "react";
import queryString from "query-string";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import AccountPage from "./AccountPage";
import ResetPasswordComponent from "../../components/account/reset-password/ResetPasswordComponent";

function ResetPasswordPage(props) {
  const { location } = props;
  const values = queryString.parse(location.search);
  const { message, messageCode, isLoading } = useSelector(
    (state) => state.resetPass
  );

  return (
    <AccountPage
      message={message}
      messageCode={messageCode}
      isLoading={isLoading}
    >
      <ResetPasswordComponent token={values.token} />
    </AccountPage>
  );
}

ResetPasswordPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default ResetPasswordPage;

import React, { Component } from "react";
import {
  Grid,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

class ResetPasswordFormComponent extends Component {
  constructor() {
    super();
    this.state = {
      showPassword: false,
      passwordCriteria: [
        {
          regex: /(?=.*[a-z])/,
          description: "Debe tener al menos una letra minúscula",
          fulfilled: false,
        },
        {
          regex: /(?=.*[A-Z])/,
          description: "Debe tener al menos una letra mayúscula",
          fulfilled: false,
        },
        {
          regex: /(?=.*\d)/,
          description: "Debe tener al menos un número",
          fulfilled: false,
        },
        {
          regex: /(?=.*[-!#$%&'()*+,./:;<=>?@^_{|}`~])/,
          description: "Debe tener al menos un carácter especial",
          fulfilled: false,
        },
        {
          regex: /^(?!\s)/,
          description: "No debe contener espacios",
          fulfilled: false,
        },
        {
          regex: /^[a-zA-Z\d\-!#$%&'()*+,./:;<=>?@^_{|}`~]{12,}$/,
          description: "Debe tener al menos 12 caracteres",
          fulfilled: false,
        },
      ],
    };
  }

  handleClickShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  };

  handlePasswordChange = (name, setFieldValue, validateForm) => (event) => {
    const password = event.target.value;
    setFieldValue(name, password);

    this.setState(
      (prevState) => ({
        passwordCriteria: prevState.passwordCriteria.map((criteria) => ({
          ...criteria,
          fulfilled: criteria.regex.test(password),
        })),
      }),
      () => validateForm()
    );
  };

  areAllCriteriaFulfilled = () => {
    const { passwordCriteria } = this.state;
    return passwordCriteria.every((criteria) => criteria.fulfilled);
  };

  render() {
    const {
      handleSubmit,
      handleBlur,
      errors,
      touched,
      isValid,
      isSubmitting,
      setFieldValue,
      validateForm,
    } = this.props;
    const { passwordCriteria, showPassword } = this.state;

    return (
      <form className="login-form" action="">
        <Grid container spacing={2} direction="row">
          <Grid item xs={12}>
            <TextField
              id="standard-full-width"
              label="Nueva contraseña"
              placeholder="Escribe tu nueva contraseña"
              fullWidth
              error={errors.password && touched.password}
              helperText={errors.password}
              onChange={this.handlePasswordChange(
                "password",
                setFieldValue,
                validateForm
              )}
              onBlur={handleBlur}
              type={showPassword ? "text" : "password"}
              margin="normal"
              name="password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="standard-full-width"
              label="Confirmar contraseña"
              placeholder="Confirma tu contraseña"
              fullWidth
              type="password"
              margin="normal"
              error={errors.confirmPassword && touched.confirmPassword}
              helperText={errors.confirmPassword}
              onChange={this.handlePasswordChange(
                "confirmPassword",
                setFieldValue,
                validateForm
              )}
              onBlur={handleBlur}
              name="confirmPassword"
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              className="submit"
              size="large"
              color="primary"
              variant="contained"
              onClick={handleSubmit}
              disabled={
                !isValid || isSubmitting || !this.areAllCriteriaFulfilled()
              }
            >
              {"Enviar"}
            </Button>
            <br />
            <br />
            <ul>
              {passwordCriteria.map((criteria, index) => (
                <li
                  key={index}
                  className={criteria.fulfilled ? "fulfilled" : "notFulfilled"}
                >
                  {criteria.description}
                </li>
              ))}
            </ul>
          </Grid>
          <Grid item xs={12}>
            <div className="login-info">
              <div className="full-item text-center">
                <span>{`Gracias por usar Appimotion © ${new Date().getFullYear()}`}</span>
              </div>
            </div>
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default ResetPasswordFormComponent;

import React from "react";

import RootPage from "../RootPage.js";

import FreeTime from "../../../components/admin/freeTime/FreeTime.jsx";

function FreeTimePage() {
  return (
    <>
      <RootPage>
        <FreeTime />
      </RootPage>
    </>
  );
}

export default FreeTimePage;
